<template>
  <div>
    <div :id="`frame_${id}`" class="surface-card surface-card--frame h-full" @click="onClick(id)">
      <div class="card-header">
        <div class="card-image mb-4" :class="`card-image--ratio-${ratio}`">
          <div v-if="error || !image" class="no-image"></div>
          <div v-else-if="image" class="image">
            <Transition name="image">
              <img v-lazy="{ src: lazyOptions.src, lifecycle: lazyOptions.lifecycle, delay: 400 }" :alt="title" />
            </Transition>
            <Transition name="image">
              <PSkeleton width="100%" height="100%" class="skeleton" v-show="!loaded" />
            </Transition>
          </div>
        </div>
      </div>
      <div class="card-body">
        <div class="body-header">
          <h3 class="card-title" v-html="title" v-if="title" />
          <p class="card-text" v-html="text" v-if="text" />
        </div>
        <div class="body-footer">
          <div class="footer-inner">
            <ButtonUI
              :label="$t('button.add_to_playlist')"
              variant="text"
              color="text-palette-1"
              feather="plus"
              class="p-button-text-inline"
              v-if="getControlPermissions('add to playlist')"
              @click="addFramePlaylist(id)"
            />
          </div>
        </div>
      </div>
    </div>
    <DialogAddFrameToPlaylist
      v-if="playlistFrameId ? true : false"
      :visible="true"
      :frameId="playlistFrameId"
      :frameType="type"
      @onCancel="onCancelAddToPlaylist"
    />
  </div>
</template>
<script>
import ButtonUI from "@/components/ButtonUI.vue";
import Skeleton from "primevue/skeleton";
import DialogAddFrameToPlaylist from "@/components/_platform/dialog/DialogAddFrameToPlaylist.vue";

import { toRefs, ref, reactive } from "vue";

export default {
  setup(props) {
    const { image } = toRefs(props);
    const src = ref(image.value);

    let loaded = ref(false);
    let error = ref(false);
    let playlistFrameId = ref(null);

    const lazyOptions = reactive({
      src: src,
      delay: 400,
      lifecycle: {
        loading: () => {
          error.value = false;
        },
        loaded: () => {
          loaded.value = true;
        },
        error: () => {
          loaded.value = true;
          error.value = true;
        },
      },
    });

    return {
      lazyOptions,
      loaded,
      error,
      playlistFrameId,
    };
  },
  props: {
    id: {
      type: [String, Number],
      required: true,
    },
    type: {
      type: String,
      default: "custom",
      validator(value) {
        return ["bcm", "custom"].includes(value);
      },
    },
    title: {
      type: String,
    },
    text: {
      type: String,
    },
    image: {
      type: String,
    },
    ratio: {
      type: String,
      default: "4-3",
    },
    controlPermissions: {
      type: Array,
      default() {
        return [];
      },
    },
  },
  emits: ["onClick"],
  components: {
    ButtonUI,
    PSkeleton: Skeleton,
    DialogAddFrameToPlaylist,
  },
  methods: {
    onClick(id) {
      this.$emit("onClick", id);
    },
    addFramePlaylist(id) {
      this.playlistFrameId = id;
    },
    removeFramePlaylist(id) {
      this.playlistFrameId = id;
    },
    onCancelAddToPlaylist() {
      this.playlistFrameId = null;
    },
    getControlPermissions(permission) {
      if (this.controlPermissions.length > 0) {
        return permission ? this.controlPermissions.includes(permission) : false;
      }
      return true;
    },
  },
};
</script>
<style lang="scss" scoped>
.image-enter-active {
  transition: all 0.15s ease-out;
}

.image-leave-active {
  transition: all 0.15s cubic-bezier(1, 0.5, 0.8, 1);
}

.image-enter-from,
.image-leave-to {
  opacity: 0;
}

.surface-card--frame {
  padding: spacing(5);
  transition: all 0.15s cubic-bezier(1, 0.5, 0.8, 1);

  .card-header {
    position: relative;
  }

  .card-image {
    aspect-ratio: 1;
    position: relative;
    overflow: hidden;
    border-radius: 3px;
    border: var(--surface-card-image-border);

    .image {
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      z-index: 0;
      .skeleton,
      img {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        object-fit: cover;
        z-index: 0;
        border-radius: 0;
      }

      .skeleton {
        z-index: 1;
      }

      img {
        z-index: 0;
        background: var(--surface-card);
      }
    }

    .card-label {
      position: absolute;
      right: -10px;
      top: -10px;
      width: 34px;
      height: 34px;
      background: var(--surface-card);
      border-radius: 0 0 0 6px;
      color: currentColor;
      z-index: 1;

      .lock-inner {
        width: 24px;
        height: 24px;
        margin-top: 10px;
        padding: 1px 0 0 6px;
      }
    }

    &--ratio-4-3 {
      aspect-ratio: 4/3;
    }

    &--ratio-16-9 {
      aspect-ratio: 16/9;
      .image {
        img {
          object-fit: contain;
        }
      }
    }

    .no-image {
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      background: var(--no-image-ground--surface);
      z-index: 0;
      &:before {
        content: "";
        background: var(--input-upload-ui-image-ground-icon) no-repeat 50% 50%;
        background-size: 100%;
        position: absolute;
        top: 50%;
        left: 50%;
        width: 30px;
        height: 30px;
        opacity: 0.1;
        transform: translateX(-50%) translateY(-50%);
        z-index: 1;
        transition: all 0.15s ease-in-out;
      }
    }
  }

  .card-title {
    font-size: font-size("lg");
    margin: 0 0 spacing(4) 0;
    word-break: break-word;
  }

  .card-text {
    color: var(--surface-card-secondary-text);
    font-size: font-size("xs");
    line-height: 1.3;
    margin: 0;
  }

  &:hover {
    transform: scale(1.1);
    box-shadow: 0 10px 30px 0 rgba(#000, 0.2);
  }
}
</style>
