<template>
  <div class="image" :class="[`image--${variant}`, `image--ratio-${ratio}`]">
    <Transition name="image">
      <div class="image-mask" v-show="src">
        <PSkeleton width="100%" height="100%" class="skeleton" v-if="!loaded" />
        <img
          v-lazy="{ src: lazyOptions.src, lifecycle: lazyOptions.lifecycle, delay: 400 }"
          :alt="alt"
          class="w-full"
          :class="{ loaded }"
        />
      </div>
    </Transition>
    <Transition name="image">
      <div v-if="!src" class="no-image"></div>
    </Transition>
  </div>
</template>
<script>
import { reactive, ref, toRefs } from "vue";
import Skeleton from "primevue/skeleton";

export default {
  setup(props) {
    const { src } = toRefs(props);
    let loaded = ref(false);
    let error = ref(false);

    const lazyOptions = reactive({
      src: src,
      delay: 400,
      lifecycle: {
        loading: () => {
          error.value = false;
        },
        loaded: () => {
          loaded.value = true;
        },
        error: () => {
          loaded.value = true;
          error.value = true;
        },
      },
    });

    return {
      lazyOptions,
      loaded,
      error,
    };
  },
  props: {
    alt: {
      type: String,
      default: "",
    },
    variant: {
      type: String,
      default: "",
    },
    src: {
      type: String,
      default: "",
    },
    ratio: {
      type: String,
      default: "",
    },
  },
  components: {
    PSkeleton: Skeleton,
  },
};
</script>
<style lang="scss" scoped>
.image-enter-active {
  transition: all 0.15s ease-out;
}

.image-leave-active {
  transition: all 0.15s cubic-bezier(1, 0.5, 0.8, 1);
}

.image-enter-from,
.image-leave-to {
  opacity: 0;
}

.image {
  aspect-ratio: 1;
  position: relative;
  overflow: hidden;
  border-radius: 3px;
  border: var(--image-border);

  &--ratio-4-3 {
    aspect-ratio: 4/3;
  }

  &--ratio-16-9 {
    aspect-ratio: 16/9;
  }

  .no-image {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 0;
    background: var(--no-image-ground);
    border: var(--no-image-border);

    &:before {
      content: "";
      background: var(--no-image-icon) no-repeat 50% 50%;
      background-size: 100%;
      position: absolute;
      top: 50%;
      left: 50%;
      width: 25px;
      height: 25px;
      opacity: 0.1;
      transform: translateX(-50%) translateY(-50%);
      z-index: 1;
      transition: all 0.15s ease-in-out;
    }
  }

  &--image {
    .no-image:before {
      background-image: var(--no-image-icon--image);
    }
  }

  &--video {
    .no-image:before {
      background-image: var(--no-image-icon--video);
    }
  }

  .image-mask {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 0;
    .skeleton,
    img {
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      object-fit: cover;
      z-index: 0;
      border-radius: 0;
    }

    img {
      opacity: 0;

      &.loaded {
        opacity: 1;
      }
    }

    .skeleton {
      z-index: 1;
    }

    img {
      z-index: 0;
      background: var(--image-ground);
    }
  }
}
</style>
