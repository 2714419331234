<template>
  <div class="view-body">
    <div class="content-wrapper">
      <div v-if="!loading">
        <NotificationMessage type="404" v-if="notFound" />
        <template v-else>
          <PageTitle :title="$t('route.slide_edit')" class="mb-7" :backUI="true" />
          <TabNav
            :items="[
              {
                title: $t('label.settings'),
                feather: 'settings',
              },
            ]"
            variant="tab"
          >
            <template #controls>
              <ButtonUI
                :underline="true"
                variant="text"
                color="text-palette-1"
                size="lg"
                :label="$t('button.delete_slide')"
                @click="deleteSlide()"
                v-if="getPermissions('delete slides')"
              />
              <ButtonUI @click="onSubmit" size="lg" :label="$t('button.save_settings')" :disabled="triggerUpload" />
            </template>
            <template #panels>
              <form @submit.prevent="onSubmit">
                <div class="grid">
                  <div class="col-7">
                    <SurfaceCard :title="$t('label.settings')">
                      <template #body>
                        <NotificationMessage :error="error" />
                        <FormRow>
                          <FormLabel for="name">{{ $t("label.media_type") }}:</FormLabel>
                          <FormSelect
                            id="media_type"
                            size="lg"
                            v-model="form.media_type"
                            :options="mediaTypeOptions"
                            optionLabel="name"
                            :placeholder="$t('placeholder.select_a_media_type')"
                          />
                          <FormHelp type="invalid" :data="error.data" field="media_type" />
                        </FormRow>

                        <hr />

                        <div class="flex">
                          <div class="col-upload flex-shrink-0">
                            <FormRow>
                              <FormLabel for="upload" required>{{ uploadTitle }}:</FormLabel>
                              <UploadUI
                                entity="slides"
                                :setChunks="true"
                                :mediaType="form.media_type?.value ?? form.media_type.value"
                                :image="uploadImage"
                                :video="uploadVideo"
                                :id="form.id"
                                :limit="limit"
                                :trigger="triggerUpload"
                                @update:trigger="triggerUpload = $event"
                                @removeFile="removeFile = $event"
                                @uploadChange="uploadChange"
                                @uploadIsCompleted="uploadIsCompleted"
                                @progress="uploadProgress = $event"
                              />
                              <FormHelp type="invalid" :data="error.data" field="upload" />
                            </FormRow>
                          </div>
                          <div class="col-fields pl-5 flex flex-column flex-grow-1 flex-shrink-1">
                            <FormRow>
                              <FormLabel for="name" required>{{ $t("label.name") }}:</FormLabel>
                              <FormText
                                id="name"
                                type="text"
                                size="lg"
                                :class="{ 'is-invalid': error.data?.name }"
                                v-model="form.name"
                              />
                              <FormHelp type="invalid" :data="error.data" field="name" />
                            </FormRow>
                            <FormRow class="mt-auto">
                              <FormLabel for="description">{{ $t("label.description") }}:</FormLabel>
                              <FormTextarea
                                id="description"
                                type="text"
                                size="lg"
                                :class="{ 'is-invalid': error.data?.description }"
                                v-model="form.description"
                              />
                              <FormHelp type="invalid" :data="error.data" field="description" />
                            </FormRow>
                          </div>
                        </div>
                        <ProgressBar :progress="uploadProgress" />
                      </template>
                    </SurfaceCard>
                  </div>

                  <div class="col-5">
                    <SurfaceCard :title="$t('label.user_playlists')" class="mb-5">
                      <ManageList
                        v-model:selected="form.playlists"
                        :add="playlistOptions"
                        :confirmTitle="$t('label.disconnect_user_playlist')"
                        :placeholder="$t('placeholder.enter_name')"
                        :messageNoData="$t('message.no_connected_playlists')"
                        :triggerSubmit="triggerSubmit"
                        @isReady="optionsAreReady = $event"
                      >
                        <template #footer>
                          <ButtonUI
                            :label="$t('button.create_playlist')"
                            color="secondary"
                            :to="{ name: 'slides-playlist-create' }"
                            v-if="
                              playlistOptions.length === 0 &&
                              form?.playlists &&
                              form.playlists.length === 0 &&
                              getPermissions('create playlists')
                            "
                          />
                          <FormHelp type="invalid" :data="error.data" field="playlists" :multipleFields="true" />
                        </template>
                      </ManageList>
                    </SurfaceCard>
                  </div>
                </div>
              </form>
              <DialogConfirm
                :visible="showConfirm"
                :title="$t('button.delete_slide')"
                :message="
                  form?.playlist_count
                    ? $t('message.associated_playlist_slide', { playlist_count: form.playlist_count })
                    : $t('message.confirmation_proceed')
                "
                @onConfirm="onDeleteConfirm"
                @onCancel="onDeleteCancel"
              />
            </template>
          </TabNav>
        </template>
      </div>
    </div>
  </div>
</template>

<script>
import PageTitle from "@/components/PageTitle.vue";
import ProgressBar from "@/components/ProgressBar.vue";
import SurfaceCard from "@/components/SurfaceCard.vue";
import FormRow from "@/components/form/FormRow.vue";
import FormLabel from "@/components/form/FormLabel.vue";
import FormText from "@/components/form/FormText.vue";
import FormSelect from "@/components/form/FormSelect.vue";
import FormTextarea from "@/components/form/FormTextarea.vue";
import FormHelp from "@/components/form/FormHelp.vue";
import ButtonUI from "@/components/ButtonUI.vue";
import UploadUI from "@/components/UploadUI.vue";
import NotificationMessage from "@/components/NotificationMessage.vue";
import DialogConfirm from "@/components/dialog/DialogConfirm.vue";
import { useAppStore, useNotificationStore as notificationStore, useSlidesStore } from "@/stores";
import { getPermissions, i18n, redirectToPreviousPage, router, setBreadcrumbs } from "@/helpers";
import TabNav from "@/components/nav/TabNav.vue";
import { storeToRefs } from "pinia";
import { useRoute } from "vue-router";
import ManageList from "@/components/ManageList.vue";

export default {
  setup() {
    setBreadcrumbs();

    const route = useRoute();
    const { slide_id } = route.params;

    const slidesStore = useSlidesStore();
    slidesStore.getMySlidesDetail(slide_id);
    slidesStore.getAvailablePlaylistsBySlide(slide_id);

    const {
      slides_detail: form,
      media_type_options: mediaTypeOptions,
      temp_created_slide: tempCreatedSlideData,
      playlists_available: playlistOptions,
      loading,
    } = storeToRefs(slidesStore);

    return {
      form,
      loading,
      slide_id,
      mediaTypeOptions,
      tempCreatedSlideData,
      playlistOptions,
    };
  },
  components: {
    ManageList,
    ProgressBar,
    TabNav,
    PageTitle,
    SurfaceCard,
    NotificationMessage,
    FormRow,
    FormLabel,
    FormText,
    FormSelect,
    FormTextarea,
    FormHelp,
    ButtonUI,
    UploadUI,
    DialogConfirm,
  },
  data() {
    return {
      showConfirm: false,
      triggerUpload: false,
      uploadChangedValidation: false,
      removeFile: false,
      hasFile: true,
      tempMediaType: null,
      error: {},
      uploadProgress: 0,
      triggerSubmit: false,
      optionsAreReady: false,
      selectedPlaylist: [],
    };
  },
  watch: {
    mediaType: {
      handler(type) {
        if (this.tempMediaType === null && type) {
          this.tempMediaType = type;
        } else {
          this.removeFile = type != this.tempMediaType;
        }
      },
      deep: true,
    },
    tempCreatedSlideData: {
      handler(slide) {
        if (slide?.data?.id) {
          this.form.id = slide.data.id;
          if (this.form.image && this.uploadChangedValidation) {
            this.triggerUpload = true;
          } else {
            this.uploadIsCompleted(true);
          }
        }
      },
    },
    validatedSubmit(submit) {
      if (submit) {
        this.error = {};
        this.form.delete_media = this.removeFile;

        // validation - no image or video exists
        if (!this.hasFile) {
          this.error = {
            status: 422,
            data: { upload: [i18n.global.t("message.field_required", { field: this.uploadTitle.toLowerCase() })] },
          };
          this.triggerSubmit = false;
          this.resetManageList();
          return false;
        }

        return useSlidesStore()
          .updateMySlides(this.form)
          .catch((error) => {
            this.error = error;
            this.triggerSubmit = false;
            this.resetManageList();
          });
      }
    },
  },
  computed: {
    limit() {
      const { uploadLimit } = useAppStore().getAppConfig;
      const type = this.form.media_type?.value ?? "image";
      return uploadLimit[type];
    },
    notFound() {
      return this.form?.status && this.form.status == 404;
    },
    uploadTitle() {
      return useSlidesStore().getStoreMediaType(this.form.media_type?.value)?.name ?? "";
    },
    uploadImage() {
      if (this.mediaType === "image" && this.form.image?.url) {
        return this.form.image;
      }
      return null;
    },
    uploadVideo() {
      if (this.mediaType === "video" && this.form.video?.url) {
        return this.form.video;
      }
      return null;
    },
    mediaType() {
      return this.form.media_type?.value ?? null;
    },
    validatedSubmit() {
      const { triggerSubmit, optionsAreReady } = this;
      return triggerSubmit && optionsAreReady;
    },
  },
  methods: {
    uploadIsCompleted(success) {
      if (success) {
        if (this.tempCreatedSlideData?.message) {
          notificationStore().saveAlert(this.tempCreatedSlideData.message);
        }
        redirectToPreviousPage("user-slides");
      }
    },
    uploadChange(image) {
      this.hasFile = !!image;
      this.form.image = image;
      this.uploadChangedValidation = !!image;
    },
    uploadError(error) {
      this.error = {};
      if (error.length > 0) {
        this.error = { message: error[0], data: { upload: [error] } };
        this.form.image = "";
      }
    },
    deleteSlide() {
      this.showConfirm = true;
    },
    onDeleteConfirm() {
      this.showConfirm = false;
      const slideStore = useSlidesStore();
      return slideStore.deleteMySlide(this.slide_id).catch((error) => {
        this.error = error;
      });
    },
    onDeleteCancel() {
      this.showConfirm = false;
    },
    resetManageList() {
      this.optionsAreReady = false;
    },
    async onSubmit() {
      this.triggerSubmit = true;
      this.resetManageList();
    },
    getPermissions,
  },
};
</script>
