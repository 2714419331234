<template>
  <div>
    <TableUI
      v-if="trackList.length > 0"
      name="tracks"
      :value="trackList"
      :multiselect="multiselect"
      @rowReorder="onRowReorder"
      v-model:rowSelectedMode="trackSelectedMode"
      v-model:rowSelected="trackSelected"
      :class="[reorder ? `table-has-reorder` : '']"
    >
      <template #header>
        <TableStickyHeader v-if="trackSelected.length > 0">
          <span v-html="selectedMessage" />
          <a
            v-if="trackSelectedMode === 'multiple' || trackSelectedMode === 'page'"
            href="#"
            @click.prevent="selectAllTracks"
            v-html="$t('message.select_all_tracks', { tracks: trackList.length })"
          />
          <a
            v-else-if="trackSelectedMode === 'all'"
            href="#"
            @click.prevent="clearTracks"
            v-html="$t('button.clear_selection')"
          />
          <ButtonUI size="md" class="px-6" :label="$t('button.add_to_playlist')" @click="addTrackPlaylist()" />
        </TableStickyHeader>
      </template>

      <template #columns>
        <PColumn v-if="reorder" rowReorder headerStyle="width: 3rem" />
        <PColumn field="title" sortable :header="$t('label.name')" :style="getCellWidth('name')">
          <template #body="slotProps">
            <HighlightTermsUI class="name" :text="slotProps.data.title" :terms="searchTerm" />
            <HighlightTermsUI class="artist" :text="slotProps.data.artist" :terms="searchTerm" />
          </template>
        </PColumn>

        <PColumn field="duration" sortable :header="$t('label.time')" :style="getCellWidth('duration')" />
        <PColumn field="media" sortable :header="$t('label.media')" :style="getCellWidth('media')">
          <template #body="slotProps">
            <div class="flex">
              <BadgeUI
                v-if="slotProps.data?.is_audio"
                :tooltip="$t('label.audio')"
                feather="music"
                variant="full-rounded"
                class="mr-1 mb-1 align-items-center"
                :class="[isPlayingAudio(slotProps.data.id) ? `badge-is-active` : '']"
                @click="playAudio(slotProps.data)"
              />

              <BadgeUI
                v-if="slotProps.data?.is_video"
                :tooltip="$t('label.video')"
                feather="monitor"
                variant="full-rounded"
                class="mr-1 mb-1 align-items-center"
                :class="[isPlayingVideo(slotProps.data.id) ? `badge-is-active` : '']"
                @click.once="playVideo(slotProps.data)"
              />
              <BadgeUI
                v-if="slotProps.data?.is_karaoke"
                :tooltip="$t('label.karaoke')"
                feather="mic"
                variant="full-rounded"
                class="mr-1 mb-1 align-items-center"
                :class="[isPlayingVideo(slotProps.data.id) ? `badge-is-active` : '']"
                @click="playKaraoke(slotProps.data)"
              />
            </div>
          </template>
        </PColumn>

        <!-- Render controls column -->
        <PColumn
          headerStyle="text-align: right"
          bodyStyle="text-align: right; overflow: visible"
          :style="getCellWidth('controls')"
          v-if="
            (controls && getControlPermissions('add to playlist')) ||
            (controls && getControlPermissions('remove track'))
          "
        >
          <template #body="slotProps">
            <div class="controls">
              <ButtonUI
                :label="$t('button.add_to_playlist')"
                variant="text"
                color="text-palette-1"
                feather="plus"
                class="p-button-text-inline"
                v-if="getControlPermissions('add to playlist')"
                @click="addTrackPlaylist(slotProps.data.id)"
              />
              <ButtonUI
                :label="$t('button.remove_from_playlist')"
                variant="text"
                color="text-palette-1"
                feather="trash-2"
                class="p-button-text-inline"
                v-if="getControlPermissions('remove track') && remove"
                @click="removeTrackPlaylist(slotProps.data.id)"
              />
            </div>
          </template>
        </PColumn>
      </template>
    </TableUI>
    <NotificationMessage type="default" v-else>{{ $t("message.no_tracks_found") }}</NotificationMessage>
    <DialogAddTrackToPlaylist
      :visible="true"
      :trackId="playlistTrackId"
      @onCancel="onCancelAddToPlaylist"
      v-if="!!playlistTrackId"
    />
    <DialogConfirm
      :visible="true"
      v-if="!!removeTrackId"
      :title="$t('button.remove_track_from_playlist')"
      @onConfirm="onConfirmRemove"
      @onCancel="onCancelRemoveFromPlayList"
    />
    <!-- Preview Media --->
    <AudioUI :data="playingAudioData" @playingAudioID="playingAudioID = $event" @stop="onStopAudio($event)" />
    <DialogVideo :visible="!!playingVideoID" :cancelTextLink="true" :data="playingVideoData" @onCancel="onStopVideo" />
  </div>
</template>

<script>
import TableUI from "@/components/TableUI.vue";
import BadgeUI from "@/components/BadgeUI.vue";
import ButtonUI from "@/components/ButtonUI.vue";
import AudioUI from "@/components/AudioUI.vue";
import DialogConfirm from "@/components/dialog/DialogConfirm.vue";
import DialogVideo from "@/components/dialog/DialogVideo.vue";
import DialogAddTrackToPlaylist from "@/components/_platform/dialog/DialogAddTrackToPlaylist.vue";
import NotificationMessage from "@/components/NotificationMessage.vue";
import TableStickyHeader from "@/components/TableStickyHeader.vue";

import Column from "primevue/column";
import { getPermissions, getTableCellWidth } from "@/helpers";

import { useMusicStore } from "@/stores";
import HighlightTermsUI from "@/components/HighlightTermsUI.vue";

export default {
  props: {
    tracks: {
      type: Array,
      default() {
        return [];
      },
    },
    filterData: {
      type: Object,
      default() {
        return {};
      },
    },
    multiselect: {
      type: Boolean,
      default: true,
    },
    reorder: {
      type: Boolean,
      default: false,
    },
    remove: {
      type: Boolean,
      default: false,
    },
    controls: {
      type: Boolean,
      default: true,
    },
    controlPermissions: {
      type: Array,
      default() {
        return [];
      },
    },
  },
  data() {
    return {
      trackList: [],
      trackSelected: [],
      trackSelectedMode: "none",
      currentAudioID: null,
      playingAudioID: null,
      playingAudioData: null,
      playingVideoID: null,
      playingVideoData: null,
      playlistTrackId: null,
      removeTrackId: null,
    };
  },
  components: {
    HighlightTermsUI,
    TableUI,
    ButtonUI,
    BadgeUI,
    AudioUI,
    DialogConfirm,
    DialogVideo,
    DialogAddTrackToPlaylist,
    NotificationMessage,
    PColumn: Column,
    TableStickyHeader,
  },
  watch: {
    tracks: {
      handler(value) {
        this.trackList = value;
      },
      immediate: true,
    },
  },
  computed: {
    selectedMessage() {
      const mode = this.trackSelectedMode;
      const selected = this.trackSelected.length;

      let message = "track_selected";
      if ((mode === "multiple" && selected > 1) || (mode === "page" && selected > 1)) {
        message = "tracks_selected";
      } else if (mode === "all") {
        message = "tracks_in_playlist_selected";
      }

      return this.$t(`message.${message}`, { tracks: selected });
    },
    searchTerm() {
      return this.filterData.term && this.filterData?.term.trim().length > 0 ? [this.filterData?.term] : [];
    },
  },
  methods: {
    selectAllTracks() {
      this.trackSelectedMode = "all";
    },
    clearTracks() {
      this.trackSelectedMode = "none";
    },
    isPlayingAudio(id) {
      return id === this.playingAudioID;
    },
    isPlayingVideo(id) {
      return id === this.playingVideoID;
    },
    playAudio(data) {
      const { id, preview_uri, is_audio } = data;

      if (is_audio) {
        let audioID = id;
        if (this.currentAudioID === id) {
          audioID = null;
        }
        this.playingAudioID = audioID;
        this.currentAudioID = audioID;

        this.playingAudioData = {
          id,
          url: preview_uri,
        };
      }
    },
    onStopAudio() {
      this.playingAudioID = null;
      this.currentAudioID = null;
      this.playingAudioData = null;
    },
    onStopVideo() {
      this.playingVideoID = null;
    },
    playVideo(data) {
      const { id, preview_uri, is_video, is_karaoke, title } = data;
      this.onStopAudio();

      if (is_video || is_karaoke) {
        this.playingVideoID = id;

        this.playingVideoData = {
          id,
          url: preview_uri,
          title,
        };
      }
    },
    playKaraoke(data) {
      this.playVideo(data);
    },
    addTrackPlaylist(id) {
      this.playlistTrackId = id ?? this.trackSelected;
    },
    removeTrackPlaylist(id) {
      this.removeTrackId = id;
    },
    async onConfirmRemove() {
      const musicStore = useMusicStore();
      const { playlist_id } = this.$route.params;
      try {
        await musicStore.removeTrackFromPlaylist({ track_id: this.removeTrackId, playlist_id }).catch((error) => {
          this.error = error;
        });
      } catch (error) {
        this.error = error;
      } finally {
        this.removeTrackId = null;
      }
    },
    onCancelRemoveFromPlayList() {
      this.removeTrackId = null;
    },
    onCancelAddToPlaylist() {
      this.playlistTrackId = null;
    },
    getPermissions,
    getCellWidth(cell) {
      return getTableCellWidth("tracks", cell);
    },
    getControlPermissions(permission) {
      if (this.controlPermissions.length > 0) {
        return permission ? this.controlPermissions.includes(permission) : false;
      }
      return true;
    },
    async onRowReorder(event) {
      if (!this.reorder) {
        return false;
      }
      this.trackList = event.value;

      const { playlist_id } = this.$route.params;
      const tracks = this.trackList.map(({ id }) => id);

      const musicStore = useMusicStore();
      await musicStore.updateTrackOrder(playlist_id, tracks);
    },
  },
};
</script>
<style lang="scss" scoped>
.artist {
  font-size: font-size("xs");
  font-weight: normal;
  line-height: 1.3;
  margin-top: 0.2rem;
  color: var(--text-color-alpha);
}
</style>
